import React, { useState, useCallback } from 'react';

import './Application.css';

import useAuthentication from '../useAuthentication';
import { useStore } from '../store';

import { Route, Switch, Redirect, Link } from 'react-router-dom';

import Dashboard from '../pages/dashboard';
import Login from '../pages/login';
import Register from '../pages/register';
import Splash from '../pages/splash';

import { Grid, Row, Col } from 'react-flexbox-grid';

import { Layout, Menu, Spin, Icon, Alert, Empty, Button } from 'antd';

import PoweredBy from './powered_by/PowerdBy';
import Forgot from '../pages/forgot';

const { /*Header,*/ Content, Footer, Sider } = Layout;

function Application() {
  const [collapsed, setCollapsed] = useState(true)

  useAuthentication();

  const { state } = useStore();

  const logout = useCallback((event) => {
    state.authentication.service.signOut()
  }, [state.authentication.service]);

  if (state.authentication.isInitialising) {
    return (
      <Grid>
        <Row center='xs' middle='xs' style={{ minHeight: '100vh' }}>
          <Col xs={12} sm={9} md={6} lg={4}>
            <Spin indicator={<Icon type='loading-3-quarters' style={{ fontSize: 24 }} spin />} />
          </Col>
        </Row>
      </Grid >
    )
  }

  if (state.authentication.isAuthenticated) {
    if (!state.authentication.user.emailVerified) {
      return (
        <Grid>
          <Row center='xs' middle='xs' style={{ minHeight: '100vh' }}>
            <Col xs={12}>
              <Empty description={<span>Please verify email</span>}>
                <Button type='primary'>Resend verification email</Button>
              </Empty>
            </Col >
          </Row >
        </Grid >
      )
    }

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={(event) => setCollapsed((collapsed) => !collapsed)}>
          <div style={{ textAlign: 'center', marginTop: '12px' }}>
            <img src={collapsed?'/wifileads-favicon.png':'/wifileads-logo-inverted.png'} height='' alt='' style={{ padding: '4px', marginBottom: '56px', maxWidth: '95%' }} />
            <PoweredBy fontSize='8pt' imgWidth='80%' fontColor='white'></PoweredBy>
          </div>
          <Menu theme='dark' mode='vertical-left'>
            <Menu.Item key='1'>
              {/* <Link to={`/splash/${state.authentication.user.uid}`}> */}
              <Link to={`/dashboard/splashPreview/${state.authentication.user.uid}`}>
                <Icon type='tablet' />
                <span>Splash Page</span>
              </Link>
            </Menu.Item>
            <Menu.Item key='2'>
              <Link to='/dashboard/meraki'>
                <Icon type='wifi' />
                <span>Meraki</span>
              </Link>
            </Menu.Item>
            <Menu.Item key='3'>
              <Link to='/dashboard/statistics'>
                <Icon type='line-chart' />
                <span>Statistics</span>
              </Link>
            </Menu.Item>
            <Menu.Item key='4'>
              <Link to='/dashboard/settings'>
                <Icon type='setting' />
                <span>Settings</span>
              </Link>
            </Menu.Item>
            <Menu.Item key='5' onClick={(event) => logout()} style={{ bottom: '64px', left: '0px', position: 'absolute' }}>
              <Icon type='poweroff' />
              <span>Sign Out</span>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          {/* <Header style={{ background: '#fff', padding: 0 }} /> */}
          <Content style={{ margin: '0 16px' }}>
            {
              state.authentication.verificationEmailSended && !state.authentication.user.emailVerified ? (<Alert
                type='info'
                message='Please verify your email address'
                banner
              />) : (<></>)
            }
            <Switch>
              <Route exact path='/splash' component={Splash}></Route>
              <Route exact path='/' component={() => <Redirect to={'/dashboard/'} />}></Route>
              <Route path='/dashboard' component={Dashboard}></Route>
              <Route exact path='/register' component={() => <Redirect to='/dashboard' />}></Route>
              <Route exact path='/login' component={() => <Redirect to='/dashboard' />}></Route>
              <Route exact path='/splash/:company' component={Splash}></Route>
            </Switch>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            <PoweredBy fontSize='5vh' imgWidth='100vw' />
          <p className="footer-text">Be sure to read our <a href='https://www.cogetix.com//swfiles/files/Privacyverklaring_3.pdf' target='_blank' rel='noopener noreferrer'>privacy statement</a> and <a href='https://cloud.sitemn.gr/users/cogetix//swfiles/files/cogetix-terms-conditions_8.pdf' target='_blank' rel='noopener noreferrer'>Terms of use</a></p>
          </Footer>
        </Layout>
      </Layout>
    );
  }

  return (
    <div>
      <Switch>
        <Route exact path='/' component={() => <Redirect to='/dashboard' />}></Route>
        <Route path='/dashboard' component={() => <Redirect to='/login' />}></Route>
        <Route exact path='/register' component={Register}></Route>
        <Route exact path='/login' component={Login}></Route>
        <Route exact path='/splash/:company' component={Splash}></Route>
        <Route exact path='/forgot' component={Forgot}></Route>
      </Switch>
      <div style={{ position: 'absolute', bottom: '24px', left: '0px', right: '0px' }}>
        <PoweredBy fontSize='20pt' imgWidth='100vw' />
      </div>
    </div>
  );
}

export default Application;
