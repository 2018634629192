import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Spin, Icon } from 'antd';
import Settings from './settings';
import Statistics from './statistics';
import Meraki from './meraki';

import { useStore } from '../../store';
import SplashPreview from '../splashPreview';

function Dashboard({ match }) {
  const { state } = useStore();

  if (state.authentication.isInitialising) {
    return (
      <Grid>
        <Row center='xs' middle='xs' style={{ height: '100vh' }}>
          <Col xs={12} sm={9} md={6} lg={4}>
            <Spin indicator={<Icon type='loading-3-quarters' style={{ fontSize: 24 }} spin />} />
          </Col>
        </Row>
      </Grid >
    )
  }

  if (state.authentication.isAuthenticated) {
    return (
      <>
        <Route exact path={`${match.path}/`} component={() => <Redirect to={`${match.path}/splashPreview/${state.authentication.user.uid}`} />}></Route>
        <Route path={`${match.path}/settings`} component={Settings} />
        <Route path={`${match.path}/statistics`} component={Statistics} />
        <Route path={`${match.path}/meraki`} component={Meraki} />
        <Route path={`${match.path}/splashPreview/:company`} component={SplashPreview}/>
      </>
    );
  }
}

export default Dashboard;
