import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Input, Tooltip, Icon } from 'antd';
import { useStore } from '../../../store';

function Meraki() {
	const { state } = useStore();

	return (
		<Grid>
			<Row center='xs' middle='xs' style={{ height: '80vh' }}>
				<Col xs={12} sm={9} md={6} lg={6}>
					<h2 className="section-header" style={{ fontStyle: 'oblique' }}>Copy the url below and set it as your custom splash page in your Cisco Meraki dashboard.</h2>
					<Input
						defaultValue={`https://wifileads.cogetix.com/splash/${state.authentication.user ? state.authentication.user.uid : ''}`}
						suffix={<Tooltip title='Paste the URL in Meraki Dashboard'><Icon type='info-circle' style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>}
						readOnly
					/>
					<br/>
					<h2 className="section-header" style={{ fontStyle: 'oblique' }}>Add the following domains to your walled garden settings:</h2>
					<ul style={{ listStyle: 'none' }}>
						<li>*.firebaseapp.com</li>
						<li>*.googleapis.com</li>
						<li>*.teamleader.eu</li>
						<li>*.cogetix.com</li>
						<li>*.firebaseio.com</li>
						<li>*.wifileads.eu</li>
					</ul>
					<br/>
					<h2 className="section-header" style={{ fontStyle: 'oblique' }}>Your walled garden settings should now look like this:</h2>
					<img src="/walled-garden-settings.png" alt="walled garden setting"/>
					<p>Visit the <a href="https://documentation.meraki.com/MR/Splash_Page/Splash_Page_Overview" target="_blank" rel="noopener noreferrer">Meraki documentation</a> for more detailed instructions.</p>
				</Col>
			</Row>
		</Grid >
	);
}

export default Meraki;
