import React from 'react';
import ReactDOM from 'react-dom';
import Application from './components/Application';
import * as serviceWorker from './serviceWorker';

import { StoreProvider } from './store';

import { BrowserRouter as Router } from 'react-router-dom';

import ErrorBoundary from './bugsnag';

ReactDOM.render(
  <ErrorBoundary>
    <StoreProvider>
      <Router>
        <Application />
      </Router>
    </StoreProvider>
  </ErrorBoundary>,

  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
