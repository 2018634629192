import React, { useState } from 'react';
import { Link } from 'react-router-dom'

import { Grid, Row, Col } from 'react-flexbox-grid';
import { Form, Icon, Input, Button } from 'antd';

import { useStore } from '../../store';

function Forgot() {
  const [email, setEmail] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [resetError, setResetError] = useState(null);

  const {state} = useStore();
  
  const sendReset = function(event) {
    event.preventDefault();
    setIsSending(true);
    state.authentication.service.sendPasswordResetEmail(email)
    .then(() => {
      setIsSent(true);
    })
    .catch(err => {
      setResetError(err);
      console.log(err);
    })
    .finally(() => {
      setIsSending(false);
    })
  };

  return (
  <Grid>
    <Row center='xs' middle='xs' style={{height: '50vh'}}>
      <Col xs={12} sm={9} md={6} lg={4}>
      <img src='./wifileads-logo.png' width='75%' height='' alt='' style={{ marginBottom: '56px' }} />
      <div style={{display: isSent? 'none': 'block'}} >
        <h4 style={{color: 'red', display: resetError? 'block': 'none', fontStyle: 'oblique'}}>Oops... Please try that again</h4>
      <h4>Reset your password</h4>
      <h2>Please enter your email address</h2>
      <Form layout='horizontal' onSubmit={(event) => sendReset(event)}>
      <Input prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />} size='large' placeholder='email' value={email} onChange={e => setEmail(e.target.value)} style={{ marginBottom: '12px' }} />
      <Button type='primary' htmlType='submit' loading={isSending} disabled={(email === '' && !isSending)} style={{ float: 'right' }}>Reset password</Button>
      </Form>
      </div>
      <div style={{display: isSent? 'block': 'none'}} >
        <h4>Email sent!</h4>
        <h2>Please check your inbox to reset your password.</h2>
        <Link to='/login'>
          <Button type='primary'>Back to login</Button>
        </Link>
      </div>
      </Col>
    </Row>
  </Grid>
  );

}

export default Forgot;