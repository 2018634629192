import React, { useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Icon, Spin, Card } from 'antd';
import { useStore } from '../../../store';
import { Chart, Axis, Tooltip, Geom } from 'bizcharts';

import { useList } from 'react-firebase-hooks/database';


function Statistics() {
	const { state } = useStore();
	const [viewData] = useState([]);
	const [successData] = useState([]);
	const [failureData] = useState([]);

	const [views, loadingViews] = useList(state.database.service.ref('users').child(state.authentication.user.uid).child('public').child('views'));
	const [successes, loadingSuccesses] = useList(state.database.service.ref('users').child(state.authentication.user.uid).child('public').child('success'));
	const [failures, loadingFailures] = useList(state.database.service.ref('users').child(state.authentication.user.uid).child('public').child('failure'));

	const pastFiveDays = Array(5).fill(new Date()).map((today, i) => new Date(today - 8.64e7 * i))

	if (!loadingViews && !loadingSuccesses && !loadingFailures) {
		viewData.push({ day: pastFiveDays[4].toLocaleString('nl', { day: '2-digit', month: '2-digit' }).replace('-', '/'), views: views.filter(view => new Date(view.val().datetime).toDateString() === pastFiveDays[4].toDateString()).length })
		viewData.push({ day: pastFiveDays[3].toLocaleString('nl', { day: '2-digit', month: '2-digit' }).replace('-', '/'), views: views.filter(view => new Date(view.val().datetime).toDateString() === pastFiveDays[3].toDateString()).length })
		viewData.push({ day: pastFiveDays[2].toLocaleString('nl', { day: '2-digit', month: '2-digit' }).replace('-', '/'), views: views.filter(view => new Date(view.val().datetime).toDateString() === pastFiveDays[2].toDateString()).length })
		viewData.push({ day: pastFiveDays[1].toLocaleString('nl', { day: '2-digit', month: '2-digit' }).replace('-', '/'), views: views.filter(view => new Date(view.val().datetime).toDateString() === pastFiveDays[1].toDateString()).length })
		viewData.push({ day: pastFiveDays[0].toLocaleString('nl', { day: '2-digit', month: '2-digit' }).replace('-', '/'), views: views.filter(view => new Date(view.val().datetime).toDateString() === pastFiveDays[0].toDateString()).length })

		successData.push({ day: pastFiveDays[4].toLocaleString('nl', { day: '2-digit', month: '2-digit' }).replace('-', '/'), successes: successes.filter(view => new Date(view.val().datetime).toDateString() === pastFiveDays[4].toDateString()).length })
		successData.push({ day: pastFiveDays[3].toLocaleString('nl', { day: '2-digit', month: '2-digit' }).replace('-', '/'), successes: successes.filter(view => new Date(view.val().datetime).toDateString() === pastFiveDays[3].toDateString()).length })
		successData.push({ day: pastFiveDays[2].toLocaleString('nl', { day: '2-digit', month: '2-digit' }).replace('-', '/'), successes: successes.filter(view => new Date(view.val().datetime).toDateString() === pastFiveDays[2].toDateString()).length })
		successData.push({ day: pastFiveDays[1].toLocaleString('nl', { day: '2-digit', month: '2-digit' }).replace('-', '/'), successes: successes.filter(view => new Date(view.val().datetime).toDateString() === pastFiveDays[1].toDateString()).length })
		successData.push({ day: pastFiveDays[0].toLocaleString('nl', { day: '2-digit', month: '2-digit' }).replace('-', '/'), successes: successes.filter(view => new Date(view.val().datetime).toDateString() === pastFiveDays[0].toDateString()).length })

		failureData.push({ day: pastFiveDays[4].toLocaleString('nl', { day: '2-digit', month: '2-digit' }).replace('-', '/'), failures: failures.filter(view => new Date(view.val().datetime).toDateString() === pastFiveDays[4].toDateString()).length })
		failureData.push({ day: pastFiveDays[3].toLocaleString('nl', { day: '2-digit', month: '2-digit' }).replace('-', '/'), failures: failures.filter(view => new Date(view.val().datetime).toDateString() === pastFiveDays[3].toDateString()).length })
		failureData.push({ day: pastFiveDays[2].toLocaleString('nl', { day: '2-digit', month: '2-digit' }).replace('-', '/'), failures: failures.filter(view => new Date(view.val().datetime).toDateString() === pastFiveDays[2].toDateString()).length })
		failureData.push({ day: pastFiveDays[1].toLocaleString('nl', { day: '2-digit', month: '2-digit' }).replace('-', '/'), failures: failures.filter(view => new Date(view.val().datetime).toDateString() === pastFiveDays[1].toDateString()).length })
		failureData.push({ day: pastFiveDays[0].toLocaleString('nl', { day: '2-digit', month: '2-digit' }).replace('-', '/'), failures: failures.filter(view => new Date(view.val().datetime).toDateString() === pastFiveDays[0].toDateString()).length })
	}

	if (loadingViews || loadingSuccesses || loadingFailures) {
		return (
			<Grid>
				<Row center='xs' middle='xs' style={{ height: '100vh' }}>
					<Col xs={12} sm={9} md={6} lg={4}>
						<Spin indicator={<Icon type='loading-3-quarters' style={{ fontSize: 24 }} spin />} />
					</Col>
				</Row>
			</Grid >
		)
	}

	return (
		/*
		<React.Fragment>
			<span>
				List:{' '}
				{
					snapshots.map(v => (
						<React.Fragment key={v.key}>{new Date(v.val().datetime).getDate()}, </React.Fragment>
					))
				}
			</span>
		</React.Fragment>
		*/

		<Grid fluid >
			<Row center='xs' around='xs' >
				<Col xs={12} sm={12} md={12} lg={4} style={{ marginTop: '24px' }}>
					<Card title='View' bordered={true} loading={loadingViews} hoverable>
						<Chart height={320} data={viewData} forceFit>
							<Axis name='day' />
							<Axis name='count' label={{ formatter: val => `${val}` }} />
							<Tooltip crosshairs={{ type: 'y' }} />
							<Geom type='line' position='day*views' size={2} color={'#E42030'} />
							<Geom type='point' position='day*views' size={4} color={'#001529'} />
						</Chart>
					</Card>
				</Col>
				<Col xs={12} sm={12} md={12} lg={4} style={{ marginTop: '24px' }}>
					<Card title='Success' bordered={true} loading={loadingSuccesses} hoverable>
						<Chart height={320} data={successData} forceFit>
							<Axis name='day' />
							<Axis name='count' label={{ formatter: val => `${val}` }} />
							<Tooltip crosshairs={{ type: 'y' }} />
							<Geom type='line' position='day*successes' size={2} color={'#E42030'} />
							<Geom type='point' position='day*successes' size={4} color={'#001529'} />
						</Chart>
					</Card>
				</Col>
				<Col xs={12} sm={12} md={12} lg={4} style={{ marginTop: '24px' }}>
					<Card title='Failure' bordered={true} loading={loadingFailures} hoverable>
						<Chart height={320} data={failureData} forceFit>
							<Axis name='day' />
							<Axis name='count' label={{ formatter: val => `${val}` }} />
							<Tooltip crosshairs={{ type: 'y' }} />
							<Geom type='line' position='day*failures' size={2} color={'#E42030'} />
							<Geom type='point' position='day*failures' size={4} color={'#001529'} />
						</Chart>
					</Card>
				</Col>
			</Row>
		</Grid>
	);
}

export default Statistics;
