import React from 'react';
import { Row } from 'antd';

function PoweredBy(props) {

	console.log(props.fontColor);
	return (
		<div style={{padding: '4px'}}>
			<Row align='middle' justify='center' type='flex'>
				{/* <Col xs={18} sm={18} md={18} lg={18} xl={18} style={{ textAlign: 'center', cursor: 'pointer'}} > */}
					{/* <span style={{ fontStyle: 'oblique', fontSize: props.fontSize || '16pt', margin: '4px', cursor: 'pointer', color: props.fontColor || 'grey' }} onClick={(e) => window.open('https://www.cogetix.com', '_blank') } >Powered by </span> */}
					<span style={{ fontStyle: 'oblique', fontSize: '10pt', margin: '4px', cursor: 'pointer', color: props.fontColor || 'grey' }} onClick={(e) => window.open('https://www.cogetix.com', '_blank') } >Powered by </span>
					<img src={props.fontColor === 'white'?'/cogetix-logo-inverted.png':'/cogetix-logo.png'} width= {props.imgWidth || '16%'} style={{margin: '4px', cursor: 'pointer'}} height='100%' alt='' onClick={(e) => window.open('https://www.cogetix.com', '_blank') }/>
				{/* </Col> */}
			</Row>
		</div>
	);
}

export default PoweredBy;
