import React from 'react';
import Splash from '../splash';
import { useStore } from '../../store';
import { Grid, Row, Col } from 'react-flexbox-grid';

function SplashPreview(props) {
const { state } = useStore();

  return (
    <Grid>
    <Row center='xs' middle='xs' style={{ height: '75vh' }}>
      <Col xs={12} sm={9} md={6} lg={4} style={{ marginTop: '32px' }}>
        <h2 className="section-header" style={{fontStyle: 'oblique'}}>This is a live preview of your splash page.</h2>
      </Col>
      <div>
        <Splash match={{ params: { company: state.authentication.user.uid } }} location={{ search: props.location.search }}></Splash>
      </div>
    </Row>
    </Grid>
  )
}

export default SplashPreview;