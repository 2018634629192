import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom'

import { Grid, Row, Col } from 'react-flexbox-grid';
import { Form, Icon, Input, Button } from 'antd';

import { useStore } from '../../store';

function Register() {
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [error, setError] = useState();

  const { state } = useStore();

  const register = useCallback((event) => {
    event.preventDefault();

    setIsAuthenticating(true);
    state.authentication.service.createUserWithEmailAndPassword(email, password)
      .then((response) => {
        console.log('createUserWithEmailAndPassword THEN', response)
        state.database.service.ref('users').child(response.user.uid).child('public').set({
          companyName: companyName,
          views: 0,
          authenticationSuccess: 0,
          authenticationFail: 0
        }).catch((error) => setError(error));
      })
      .catch((error) => setError(error))
      .finally(() => setIsAuthenticating(false));

  }, [state, email, password, companyName]);

  return (
    <Grid>
      <Row center='xs' middle='xs' style={{ height: '75vh' }}>
        <Col xs={12} sm={9} md={6} lg={4}>
          <img src='./wifileads-logo.png' width='75%' height='' alt='' style={{ marginBottom: '56px' }} />
          <Form layout='horizontal' onSubmit={(event) => register(event)}>
            <Input prefix={<Icon type='shop' style={{ color: 'rgba(0,0,0,.25)' }} />} size='large' placeholder='company' value={companyName} onChange={e => setCompanyName(e.target.value)} style={{ marginBottom: '12px' }} />
            <Input prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />} size='large' placeholder='email' value={email} onChange={e => setEmail(e.target.value)} style={{ marginBottom: '12px' }} />
            <Input.Password prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} size='large' placeholder='password' value={password} onChange={e => setPassword(e.target.value)} style={{ marginBottom: '12px' }} />
            <Button type='primary' htmlType='submit' loading={isAuthenticating} disabled={(companyName === '') || (email === '') || (password === '')} style={{ float: 'right' }}>Register</Button>
            <Link to='/login'>
              <Button type='dashed' style={{ float: 'right', marginRight: '8px' }}>Sign In</Button>
            </Link>
            <p style={{ color: 'red' }}>{error ? error.message : ''}</p>
          </Form>
        </Col>
      </Row>
    </Grid >
  );
}


export default Register;
