import React, { useState, useEffect } from 'react';

import { Grid, Row, Col } from 'react-flexbox-grid';
import { Form, Icon, Input, Button, Checkbox, Spin } from 'antd';

import { useStore } from '../../store';

import { useObject } from 'react-firebase-hooks/database';

import firebase from 'firebase/app';
import 'firebase/database';

function Splash(props) {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [marketingMailsConsent, setMarketingMailsConsent] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [error, setError] = useState(false);

  const { state } = useStore();

  const [value, loading, errorr] = useObject(state.database.service.ref('users').child(props.match.params.company).child('public'));

  let params = new URLSearchParams(props.location.search);

  useEffect(() => {
    if (!state.authentication.isAuthenticated) {
      state.database.service.ref('users').child(props.match.params.company).child('public').child('views').push().set({
        datetime: firebase.database.ServerValue.TIMESTAMP
      });
    }
  }, [state.authentication.isAuthenticated, props.match.params.company, state.database.service])

  const register = (event) => {
    event.preventDefault();
    setIsAuthenticating(true)

    state.database.service.ref('leads').push().set({
      companyId: props.match.params.company,
      firstname,
      lastname,
      email,
      marketingMailsConsent
    }).then(() => {
      state.database.service.ref('users').child(props.match.params.company).child('public').child('success').push().set({
        datetime: firebase.database.ServerValue.TIMESTAMP
      });
      window.location.replace(`${params.get('base_grant_url')}?user_continue_url=${params.get('user_continue_url')}?duration=60`);
    }).catch((error) => {
      state.database.service.ref('users').child(props.match.params.company).child('public').child('failure').push().set({
        datetime: firebase.database.ServerValue.TIMESTAMP,
        error: error.message
      });
      setError(error);
    }).finally(() => setIsAuthenticating(false));
  }

  if (loading) {
    return (
      <Grid>
        <Row center='xs' middle='xs' style={{ height: '100vh' }}>
          <Col xs={12} sm={9} md={6} lg={4}>
            <Spin indicator={<Icon type='loading-3-quarters' style={{ fontSize: 24 }} spin />} />
          </Col>
        </Row>
      </Grid >
    )
  }

  if (value.val()) {
    if (value.val().isAuthorized) {
      return (
        <Grid style={{ minHeight: '75vh', overflowY: 'scroll'}}>
          <Row center='xs' middle='xs' style={{ minHeight: '75vh' }}>
            <Col xs={12} sm={9} md={6} lg={4}>
              {/* <img src='./wifileads-logo.png' width='75%' height='' alt='' style={{ marginBottom: '56px' }} /> */}
              <Form layout='horizontal' onSubmit={(event) => register(event)}>
                <Input prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />} size='large' placeholder='firstname' value={firstname} onChange={e => setFirstname(e.target.value)} style={{ marginBottom: '12px' }} />
                <Input prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />} size='large' placeholder='lastname' value={lastname} onChange={e => setLastname(e.target.value)} style={{ marginBottom: '12px' }} />
                <Input pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$' type='email' prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />} size='large' placeholder='email' value={email} onChange={e => setEmail(e.target.value)} style={{ marginBottom: '12px' }} />
                <Row>
                  <Col xs={12} style={{ textAlign: 'left' }}>
                    <label>Would you like to receive information from {value.val().companyName || '...'}?</label>
                  </Col>
                </Row>
                <Row style={{ marginTop: '4px' }}>
                  <Col xs={6} style={{ textAlign: 'left' }}>
                    <Checkbox checked={marketingMailsConsent} onChange={e => setMarketingMailsConsent(e.target.checked)}>Yes</Checkbox>
                    <Checkbox checked={!marketingMailsConsent} onChange={e => setMarketingMailsConsent(!e.target.checked)}>No</Checkbox>
                  </Col>
                </Row>
                <Button type='primary' htmlType='submit' loading={isAuthenticating} disabled={(email === '') || (firstname === '') || (lastname === '')} style={{ float: 'right' }}>Sign In</Button>

                <p style={{ color: 'red' }}>{error ? error.message : ''}</p>

              </Form>
              <p>
                {errorr && <strong>Error: {errorr.message}</strong>}
              </p>
            </Col>
          </Row>
        </Grid >
      );
    }

    return (
      <div>
        Please contact administrator for authorizing Teamleader
      </div>
    )
  }

  return (
    <div>
      404 not found
    </div>
  )
}

export default Splash;
