import React, { useCallback } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useStore } from '../../../store';
import OauthPopup from 'react-oauth-popup';
import { Card, Menu, Spin, Icon, Button, Popover } from 'antd';

import { useObjectVal } from 'react-firebase-hooks/database';

function Settings() {
	const { state } = useStore();
	const authorizationUrl = `https://app.teamleader.eu/oauth2/authorize?client_id=0dadb3c374260de1b7c8d0a4d048e7e5&response_type=code&redirect_uri=https://us-central1-wifileads-b592d.cloudfunctions.net/teamleaderAuthorize&state=${state.authentication.user ? state.authentication.user.uid : ''}`;

	const [teamleader, loading, error] = useObjectVal(state.database.service.ref('users').child(state.authentication.user.uid).child('private').child('teamleader'));

	console.log({ error });

	const revokeTeamleader = useCallback((e) => {
		state.database.service.ref('users').child(state.authentication.user.uid).child('private').child('teamleader').remove();
		state.database.service.ref('users').child(state.authentication.user.uid).child('public').child('isAuthorized').set(false);
	}, [state.database.service, state.authentication.user.uid]);

	const removeAccount = useCallback((e) => {
		console.log('deleter account');
		state.authentication.service.currentUser.delete();
	}, [state.authentication.service]);

	if (loading) {
		return (
			<Grid fluid>
				<Row center='xs' middle='xs' style={{ height: '100vh' }}>
					<Col xs={12} sm={9} md={6} lg={4}>
						<Spin indicator={<Icon type='loading-3-quarters' style={{ fontSize: 24 }} spin />} />
					</Col>
				</Row>
			</Grid >
		);
	};

	const content = (
		<Menu mode='vertical' selectable={false} onClick={({ key }) => removeAccount()}>
			<Menu.Item key='1'><Icon type='poweroff' />DELETE ACCOUNT</Menu.Item>
		</Menu>
	);

	if (teamleader) {
		return (
			<Grid >
				<div style={{ position: 'absolute', top: '24px', right: '24px' }} >
					<Popover placement='bottomLeft' trigger="click" content={content} >
						<Icon type='more' />
					</Popover>
				</div>
				<Row center='xs' middle='xs' style={{ height: '80vh' }}>
					<Col>
					<h3 className='section-header'>Click the button to unlink Teamleader</h3>
						<Card title="Teamleader Authentication" bordered={true} style={{ width: 300 }} hoverable>
							<Button size={'large'} style={{ backgroundColor: '#00b2B2', color: 'white' }} onClick={(e) => revokeTeamleader(e)}>
								Revoke Teamleader Access
							</Button>
						</Card>
					</Col>
				</Row>
			</Grid >
		);
	};

	return (
		<Grid >
			<div style={{ position: 'absolute', top: '24px', right: '24px' }} >
				<Popover placement='bottomLeft' trigger="click" content={content} >
					<Icon type='more' />
				</Popover>
			</div>
			<Row center='xs' middle='xs' style={{ height: '80vh' }}>
				<Col>
				<h2 className='section-header'>Click the button to link Teamleader</h2>
					<Card title="Teamleader Authentication" bordered={true} style={{ width: 300 }} hoverable>
						<Button size={'large'} style={{ backgroundColor: '#00b2B2', color: 'white' }}>
							<OauthPopup
								url={authorizationUrl}
								onCode={(code, params) => console.log("onClose")}
								onClose={() => console.log('closed')}
								height='620'
							>
								<div>Authenticate Teamleader</div>
							</OauthPopup>
						</Button>
					</Card>
				</Col>
			</Row>
		</Grid >
	);
};

export default Settings;
