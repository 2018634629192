import React, { createContext, useReducer, useMemo, useContext } from 'react';

const StoreContext = createContext();
const DispatchContext = createContext();

const initialState = {
  authentication: {
    isInitialising: true,
    isAuthenticated: false,
    isLoading: false,
    service: null,
    error: null,
    user: null,
    verificationEmailSended: null
  },
  database: {
    service: null
  },
  teamleader: {
    accessToken: null
  }
};

function reducer(state, action) {
  switch (action.type) {
    case 'setIsInitialising':
      return { ...state, authentication: { ...state.authentication, isInitialising: action.payload.isInitialising } };
    case 'setIsLoading':
      return { ...state, authentication: { ...state.authentication, isLoading: action.payload.isLoading } };
    case 'setIsAuthenticated':
      return { ...state, authentication: { ...state.authentication, isAuthenticated: action.payload.isAuthenticated } };
    case 'setAuthenticationService':
      return { ...state, authentication: { ...state.authentication, service: action.payload.service } };
    case 'setDatabaseService':
      return { ...state, database: { ...state.database, service: action.payload.service } };
    case 'setUser':
      return { ...state, authentication: { ...state.authentication, user: action.payload.user } };
    case 'setTeamleaderAccessToken':
      return { ...state, teamleader: { ...state.teamleader, accessToken: action.payload.accessToken } };
    case 'setVerificationEmailSended':
      return { ...state, authentication: { ...state.authentication, verificationEmailSended: action.payload.verificationEmailSended } };

    default:
      throw new Error(`action ${action.type} not found`);
  }
}

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const memoizedState = useMemo(() => (state), [state]);

  return (
    <StoreContext.Provider value={{ state: memoizedState }}>
      <DispatchContext.Provider value={{ dispatch }}>
        {children}
      </DispatchContext.Provider>
    </StoreContext.Provider>
  );
};


export const useStore = () => useContext(StoreContext);
export const useDispatch = () => useContext(DispatchContext);
