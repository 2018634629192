import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom'

import { Grid, Row, Col } from 'react-flexbox-grid';
import { Form, Icon, Input, Button } from 'antd';

import { useStore } from '../../store';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [error, setError] = useState();

  const { state } = useStore();

  const login = useCallback((event) => {
    event.preventDefault();
    setError(null);
    setIsAuthenticating(true);

    state.authentication.service.signInWithEmailAndPassword(email, password)
      .catch((error) => {
        switch (error.code) {
          case 'auth/invalid-email':
            setError('Email is invalid.');
            break;
          case 'auth/user-not-found':
            setError('User not found.');
            break;
          case 'auth/wrong-password':
            setError('Wrong password.');
            break;
          default:
            setError(error.message)
        }

      })
      .finally(() => setIsAuthenticating(false))

  }, [state.authentication, email, password]);

  return (
    <Grid>
      <Row center='xs' middle='xs' style={{ height: '75vh' }}>
        <Col xs={12} sm={9} md={6} lg={4}>
          <img src='./wifileads-logo.png' width='75%' height='' alt='' style={{ marginBottom: '56px' }} />
          <Form layout='horizontal' onSubmit={(event) => login(event)}>
            <Input prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />} size='large' placeholder='email' value={email} onChange={e => setEmail(e.target.value)} style={{ marginBottom: '12px' }} />
            <Input.Password prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} size='large' placeholder='password' value={password} onChange={e => setPassword(e.target.value)} style={{ marginBottom: '12px' }} />
            <Button type='primary' htmlType='submit' loading={isAuthenticating} disabled={(email === '') || (password === '')} style={{ float: 'right' }}>Sign In</Button>
            <Link to='/register'>
              <Button type='dashed' style={{ float: 'right', marginRight: '8px' }}>Register</Button>
            </Link>
            <div style={{ color: 'red', textAlign: 'start' }}><p>{error ? error : ''}</p></div>
          </Form>
        </Col>
      </Row>
      <Row center='xs' middle='xs'>
      <Link to='/forgot' style={{fontStyle: 'oblique'}}>I forgot my password...</Link>
      </Row>
    </Grid >
  );
}

export default Login;
