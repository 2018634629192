import { useEffect } from 'react';
import { useStore, useDispatch } from './store';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/performance';

firebase.initializeApp({
  apiKey: 'AIzaSyDrwoIOiMluM6-d3t6IBy0ooQI5GEz61WI',
  authDomain: 'wifileads-b592d.firebaseapp.com',
  databaseURL: 'https://wifileads-b592d.firebaseio.com',
  projectId: 'wifileads-b592d',
  storageBucket: 'wifileads-b592d.appspot.com',
  messagingSenderId: '793981296992',
  appId: '1:793981296992:web:2b59caba0c6e521e'
});

const performance = firebase.performance(); 

const useAuthentication = () => {
  const trace = performance.trace('testTrace');
  trace.start();
  

  const firebaseAuth = firebase.auth();
  const firebaseDatabase = firebase.database();

  const { state } = useStore();
  const { dispatch } = useDispatch();
  
  trace.stop();

  useEffect(() => {
    dispatch({ type: 'setAuthenticationService', payload: { service: firebaseAuth } });
    dispatch({ type: 'setDatabaseService', payload: { service: firebaseDatabase } });

    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      dispatch({ type: 'setIsInitialising', payload: { isInitialising: false } });

      if (user) {
        dispatch({ type: 'setUser', payload: { user: user } });
        dispatch({ type: 'setIsAuthenticated', payload: { isAuthenticated: true } });
        state.database.service.ref('users').child(user.uid).child('private').child('teamleader').child('accessToken').on('value', (snapshot) => {
          dispatch({ type: 'setTeamleaderAccessToken', payload: { accessToken: snapshot.val() } });
        });
        state.database.service.ref('users').child(user.uid).child('private').child('verificationEmailSended').on('value', (snapshot) => {
          dispatch({ type: 'setVerificationEmailSended', payload: { verificationEmailSended: snapshot.val() } });

          if (!user.emailVerified && !snapshot.val()) {
            console.log('send mail')
            user.sendEmailVerification().then(() => {
              state.database.service.ref('users').child(user.uid).child('private').child('verificationEmailSended').set(true);
            }).catch(function (error) {
              console.log('errorrr', error)
            });
          }
        });
      } else {
        console.log('logged out')
        dispatch({ type: 'setIsAuthenticated', payload: { isAuthenticated: false } });
        dispatch({ type: 'setUser', payload: { user: null } });
      }
    });
    
    return () => unsubscribe()
  }, [firebaseAuth, firebaseDatabase, dispatch, state.database.service, state.authentication.verificationEmailSended]);

  return state.authentication;
}

export default useAuthentication;
